/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: tableColumns.js
Description: This file contains the table columns of the pondInfo tab at customer end for pondlogs
*/
const pondInfoColumns = {
  defaultColumns: {
    title: {
      key_name: "title",
      order: 1
    },
    size: {
      key_name: "size",
      order: 2
    },
    days_of_cultivation: {
      key_name: "days_of_cultivation",
      order: 3
    },
    abw: {
      key_name: "abw",
      order: 4
    },
    awg: {
      key_name: "awg",
      order: 5
    },
    biomass: {
      key_name: "biomass",
      order: 6
    },
    total_feed: {
      key_name: "total_feed",
      order: 7
    },
    feed_per_area: {
      key_name: "feed_per_area",
      order: 7
    },
    post_larva_stocked: {
      key_name: "post_larva_stocked",
      order: 8
    },
    survival_percentage: {
      key_name: "survival_percentage",
      order: 9
    },
    fcr: {
      key_name: "fcr",
      order: 10
    },
    mode: {
      key_name: "mode",
      order: 11
    }
  },
  visibleColumnCount: 12,
  pluggedColumns: {},
  listeners: {},
  getOrderOfColumnKeys() {
    const columns = {};
    for (let i = 0; i < this.visibleColumnCount; i++) {
      columns[i + 1] = "";
    }
    const arrColumns = Object.values(this.defaultColumns).sort((a, b) => {
      return a.order - b.order;
    });
    arrColumns.splice(1, 0);
    return Object.values(this.pluggedColumns)
      .map(x => Object.values(x))
      .flat(1)
      .reduce((accCol, currCol) => {
        accCol.splice(currCol.order - 1, 0, currCol);
        return accCol;
      }, arrColumns);
  },
  register(newColumnsObj) {
    const { colGroupName, columns } = newColumnsObj;
    this.pluggedColumns[colGroupName] = columns;
    if (this.listeners.onColumnsChange) {
      this.listeners.onColumnsChange.forEach(x => x());
    }
  },
  unRegister(newColumnsObj) {
    const { colGroupName } = newColumnsObj;
    delete this.pluggedColumns[colGroupName];
  },
  addListeners({ event, callBack }) {
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }
    this.listeners[event].push(callBack);
  }
};

export default pondInfoColumns;
