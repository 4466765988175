<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: cardChart.vue
Description:this file gives the info about the graphs inside the pondInfoTab.it is the child component of infoCharts
-->
<template>
  <el-row
    class="card-chart"
    ref="card-chart"
    :key="this.upm__getFormatDateString"
  >
    <er-card shadow="never" :style="`min-height:${height}px`">
      <template slot="header">
        <!-- <el-row type="flex" align="center">
          <slot name="title" class="header__title card-title"></slot> -->
        <layout-toolbar
          class="card-toolbar"
          justify="end"
          seperator="space"
          align="center"
          gap="5"
        >
          <div class="header__title card-title">
            <slot name="title"></slot>
          </div>
          <div class="filler"></div>
          <er-date-picker
            size="mini"
            popper-class="card-chart__date-picker"
            :value="initDateRangeArr"
            :timeZoneString="timeZoneString"
            :value-format="datePickerFormat"
            :format="upm__getFormatDateString"
            :picker-options="datePickerOptions.pickerOptions"
            :disableDateMethod="datePickerOptions.disableDateMethod"
            :arrShortcuts="datePickerOptions.arrShortcuts"
            :availableInterval="
              datePickerOptions.availableInterval !== undefined
                ? datePickerOptions.availableInterval
                : -1
            "
            :disabled="datePickerDisabled"
            @input="handleDateRangeChange"
          ></er-date-picker>

          <el-radio-group
            class="radio-btn-group"
            v-model="selectedView"
            size="mini"
            @change="handleChangeInView"
          >
            <el-radio-button label="GRAPH">
              <svg
                class="icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 30.579 22.934"
              >
                <path
                  d="M19.877 15.29h2.293a.822.822 0 00.764-.764V6.5a.822.822 0 00-.764-.764h-2.293a.822.822 0 00-.764.764v8.027a.822.822 0 00.764.763zm5.734 0H27.9a.822.822 0 00.764-.764V.764A.822.822 0 0027.9 0h-2.29a.822.822 0 00-.764.764v13.761a.822.822 0 00.764.765zm-17.2 0H10.7a.822.822 0 00.764-.764v-4.2a.822.822 0 00-.764-.764H8.409a.822.822 0 00-.764.764v4.2a.822.822 0 00.764.764zm5.734 0h2.293a.822.822 0 00.764-.764V2.676a.822.822 0 00-.764-.764h-2.295a.822.822 0 00-.764.764v11.849a.822.822 0 00.764.765zm15.481 3.822H3.822V.956A.955.955 0 002.867 0H.956A.955.955 0 000 .956v20.067a1.911 1.911 0 001.911 1.911h27.713a.955.955 0 00.956-.956v-1.91a.955.955 0 00-.956-.956z"
                />
              </svg>
            </el-radio-button>
            <el-radio-button label="TABLE">
              <svg
                class="icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20.347 17.804"
              >
                <path
                  d="M18.439 0H1.908A1.908 1.908 0 000 1.908V15.9a1.908 1.908 0 001.908 1.9h16.531a1.908 1.908 0 001.908-1.9V1.908A1.908 1.908 0 0018.439 0zM8.9 15.26H2.543v-3.815H8.9zm0-6.358H2.543V5.087H8.9zm8.9 6.358h-6.355v-3.815H17.8zm0-6.358h-6.355V5.087H17.8z"
                />
              </svg>
            </el-radio-button>
          </el-radio-group>
        </layout-toolbar>
        <!-- </el-row> -->
      </template>
      <el-row :key="$i18n.locale">
        <template v-if="selectedView === 'GRAPH'">
          <high-charts
            :options="chartConfig"
            ref="highcharts"
            constructor-type="stockChart"
          ></high-charts>
          <slot name="legend"></slot>
        </template>
        <template v-else>
          <el-row v-if="columns" class="table-row">
            <graph-data-table
              style="width: 100%"
              :height="height - legendContainerHeight - toolBarHeight"
              :data="tableData"
              :columns="columns"
            ></graph-data-table>
            <el-row class="legend-container" ref="legendContainer">
              <el-col class="legend legend--right" :span="24">
                <div
                  v-for="item in getLegend"
                  :key="item.color"
                  class="legend__item"
                >
                  <div
                    class="color-box"
                    :style="{ 'background-color': item.color }"
                  />
                  <p class="title">{{ item.name }}</p>
                </div>
              </el-col>
            </el-row>
          </el-row>
          <el-row v-else class="no-table-data">
            <el-tag>{{ $t('Comn_no_data') }}</el-tag>
          </el-row>
        </template>
      </el-row>
    </er-card>
  </el-row>
</template>

<script>
import { mapGetters } from "vuex";
import graphDataTable from "../graphs/graphDataTable";
import Highcharts from "highcharts";
import resizeContainerMixin from "@/mixins/resizeContainerMixin";
export default {
  name: "CardChart",
  mixins: [resizeContainerMixin],
  props: {
    keyToStoreDefaults: {
      required: true
    },
    timeZoneString: {
      required: true,
      default: 0
    },
    columns: {
      required: true
    },
    tableData: {
      required: true,
      default: () => []
    },
    chartConfig: {
      default: () => ({
        chart_options: {
          xAxis: { title: { text: "" } },
          yAxis: { title: { text: "" } }
        },
        series_generator: {},
        xAxis: {},
        yAxis: {}
      }),
      required: true
    },
    defaultDropdownOptionKey: {
      default: ""
    },
    xAxisTitle: {
      required: true
    },
    xAxisType: {
      default: "datetime"
    },
    yAxisTitle: {
      required: true
    },
    datePickerOptions: {
      required: false,
      default: () => ({})
    },
    datePickerDisabled: {
      default: false
    },
    initDateRangeArr: {
      required: false,
      default: () => [new Date(), new Date()]
    },
    datePickerFormat: {
      required: false,
      default: "yyyy-MM-dd"
    },
    height: {
      default: "250"
    },
    width: {
      default: null
    }
  },
  components: {
    graphDataTable
  },
  data: function() {
    return {
      selectedCommand: undefined,
      selectedHeaders: {},
      selectedView: "GRAPH",
      viewToComponent: {
        graph: "highCharts",
        table: "erDataTables"
      },
      dateSelected: this.initDateRangeArr,
      legendContainerHeight: 35,
      toolBarHeight: 60
    };
  },
  computed: {
    ...mapGetters("user", {
      getPreferredUnits: "getPreferredUnits"
    }),
    getDefaultDate() {
      const date = new Date();
      date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
      return date;
    },

    getLegend() {
      const { series } = this.chartConfig;
      const list = [...series];

      const fList = list
        .map(({ nameLg, tableLgOrder }, index) => {
          return {
            name: nameLg,
            tableLgOrder,
            color: tableLgOrder === 1 ? "green" : "blue"
          };
        })
        .slice(0, 2);
      fList.sort((a, b) => a.tableLgOrder - b.tableLgOrder);
      return fList;
    },
    computedDropdownOptionKey: function() {
      const firstTimeOptionKey = Object.keys(this.timeDropdownOptions || {})[0];
      return (
        this.selectedCommand ||
        this.defaultDropdownOptionKey ||
        firstTimeOptionKey
      );
    }
  },
  watch: {
    "$i18n.locale": {
      handler: function(newLang, oldLang) {
        this.initChartAxisTitle();
        this.handleChangeInView();
        const lang = {
          loading: this.$t("Comn_loading_pls_wait"),
          noData: this.$t("Comn_no_data_connection"),
          numericSymbolMagnitude: 1000,
          rangeSelectorZoom: this.$t("Comn_zoom"),
          resetZoom: this.$t("Comn_reset_zoom"),
          resetZoomTitle: this.$t("Comn_reset_zoom_1_1")
        };
        Highcharts.setOptions({ lang });
        this.$nextTick(() => {
          const chart = this.$refs.highcharts.chart;
          if (!chart.hasData()) {
            chart.showNoData(this.$t("Comn_no_data_connection"));
          }
        });
      },
      immediate: true
    },
    chartConfig: function(newConfig, oldConfig) {
      if (Object.keys(this.$refs).length > 0) {
        if (!this.$refs.highcharts) return;
        const xAxis = this.$refs.highcharts.chart.xAxis[0];
        if (newConfig.xAxis.min > -1 && newConfig.xAxis.max > -1) {
          xAxis.setExtremes(newConfig.xAxis.min, newConfig.xAxis.max);
        }
      }
    },
    width: function(newWidth) {
      this.$refs.highcharts.chart.update({ chart: { width: newWidth } });
    }
  },
  mounted() {
    this.rcm__registerResizeObserver(
      "card-chart",
      this.rcm__debounce(200, () => {
        if (this.$refs.highcharts) {
          this.$refs.highcharts.chart.reflow();
        }
      })
    );
  },
  beforeDestroy() {
    this.rcm__unregisterResizeObserver("card-chart", () => {
      if (this.$refs.scaleContainer) {
        this.scaleContainerWidth = this.$refs.scaleContainer.$el.offsetWidth;
      }
    });
  },
  methods: {
    initChartAxisTitle() {
      this.chartConfig.xAxis.title.text = this.xAxisTitle;
      if (Array.isArray(this.chartConfig.yAxis)) {
        this.yAxisTitle.forEach((title, index) => {
          this.chartConfig.yAxis[index].title.text = title;
        });
      } else {
        this.chartConfig.yAxis.title.text = this.yAxisTitle;
      }
    },
    handleDateRangeChange(dateRange) {
      this.$emit("date-query", dateRange);
    },
    handleChangeInColumnSelection(selectedColumns) {
      const columnObj = selectedColumns.reduce((acc, curr) => {
        acc[curr] = this.tableColumns[curr];
        return acc;
      }, {});
      this.selectedHeaders = Object.assign({}, columnObj);
    },
    handleChangeInView(value) {
      if (this.$refs.highcharts) {
        this.$refs.highcharts.chart.xAxis[0].update({
          type: this.xAxisType
        });
      } else {
        this.chartConfig.xAxis.type = this.xAxisType;
      }
    },
    seriesGenerator: function(sampleSeries, data, color, title) {
      const series = this.$lodash.cloneDeep(sampleSeries);
      series.data = data;
      series.color = color;
      series.name = title;
    }
  }
};
</script>

<style lang="scss">
.card-chart__date-picker {
  .el-picker-panel [slot="sidebar"] + .el-picker-panel__body,
  .el-picker-panel__sidebar + .el-picker-panel__body {
    margin-left: 130px;
  }
}
.card-chart {
  // card styling
  .er-card.er-card--secondary {
    .el-card__header {
      @include responsiveProperty(font-size, 1em, 0.9em, 0.8em, false);
      @include responsiveProperty(line-height, 1, 1, 0.8, false);
      // opacity: 0.75;
    }
    .el-card__body {
      padding: 0px 5px 10px;
    }
  }
  .card-toolbar > .el-row,
  .header__title {
    min-width: 50px;
    white-space: break-spaces;
    flex-shrink: 13;
  }
  .el-range-editor--mini.el-input__inner {
    flex-basis: 50%;
  }
  .el-input__icon.el-range__close-icon {
    display: none;
  }
  .radio-btn-group {
    display: inline-flex;
    align-items: center;
    line-height: 1.5;
    .el-radio:not(:last-child) {
      margin-right: 10px;
    }
    .el-radio-button {
      .icon {
        @include responsiveProperty(width, 1em, 1.3em, 1.5em);
        @include responsiveProperty(height, 1em, 1.3em, 1.5em);

        fill: black;
      }
      &.is-active {
        .icon path {
          fill: white;
        }
        .el-radio-button__inner {
          background-color: #0a2463 !important;
          border-color: #0a2463 !important;
          box-shadow: -1px 0 0 0 #0a2463;
          -webkit-box-shadow: -1px 0 0 0 #0a2463;
        }
      }
      .el-radio-button__inner {
        padding: 5px 11px;
        .el-radio__inner {
          width: 15px;
          height: 15px;
          &:hover {
            border-color: #333333;
          }
        }
      }
    }
  }
  .icon {
    vertical-align: middle;
  }
  // highchart styling
  $stroke-color: $highcharts-axis-stroke-color;
  .highcharts-axis-title {
    fill: $highcharts-axis-title-color !important;
    @include highcharts-axis-title-font-size;
  }
  .highcharts-xaxis {
    .highcharts-axis-line {
      stroke: $stroke-color;
    }
    .highcharts-tick {
      stroke: $stroke-color;
    }
  }

  .highcharts-xaxis-labels {
    &.highcharts-axis-labels text {
      color: $stroke-color !important;
      @include highcharts-axis-labels-font-size;
      fill: $stroke-color !important;
    }
  }
  .highcharts-yaxis-labels {
    &.highcharts-axis-labels text {
      color: $stroke-color !important;
      @include highcharts-axis-labels-font-size;
      fill: $stroke-color !important;
    }
  }
  .highcharts-tooltip {
    span {
      @include highcharts-tooltip-font-size;
    }
  }
  // charts scroll bar styling
  .highcharts-scrollbar-thumb {
    fill: $stroke-color;
    stroke: $stroke-color;
    opacity: 0.5;
    stroke-width: 1px;
  }

  .highcharts-scrollbar-button {
    fill: #ffffff;
    stroke: #00000066;
    stroke-width: 1px;
  }

  .highcharts-scrollbar-arrow {
    fill: $stroke-color;
  }

  .highcharts-scrollbar-rifles {
    stroke: #ffffff;
    stroke-width: 1px;
  }

  .highcharts-scrollbar-track {
    fill: #f2f2f2;
    stroke: #00000066;
    stroke-width: 1px;
  }
  //graph data table styling
  .no-table-data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh;
    @include responsiveProperty(font-size, 40vh, 50vh, 60vh);
  }
  .el-table thead {
    color: #787878;
    .cell {
      font-weight: 500;
    }
  }
  .el-table td:first-child {
    font-weight: 700;
  }
}
</style>
