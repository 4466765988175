<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: graphDataTable.vue
Description:This is the child component of the cardChart component.This component gives the information about graphs in the form of numerical representation
-->
<template>
  <el-table
    :data="data"
    class="base-table"
    :resizable="true"
    :cellClassName="cellClassName"
    :default-sort="{ prop: '', order: 'ascending' }"
  >
    <el-table-column
      v-for="(eachColumn, key) in columns"
      :key="`${eachColumn.label} ${eachColumn.subLabel || ''} ${
        Object.keys(columns).length
      }`"
      :prop="key"
      :width="eachColumn.width"
      :sortable="eachColumn.sortable"
      :fixed="eachColumn.fixed ? eachColumn.fixed : false"
      :header-cell-class-name="rowClass"
      :header-row-style="rowClass"
      :header-row-class-name="rowClass"
      :align="eachColumn.align || 'center'"
      :header-align="eachColumn.align || 'center'"
    >
      <template slot="header">
        <div class="heading">
          <p>{{ eachColumn.label }}</p>
          <p v-if="eachColumn.subLabel">
            {{ eachColumn.subLabel ? eachColumn.subLabel : "" }}
          </p>
        </div>
      </template>
      <template slot-scope="{ row }">
        <slot name="data-cell" :cellData="{ key, row }">
          <template v-if="key === 'title'">{{ row[key] }}</template>
          <template v-else>
            <div class="row-cell">
              <p
                style="color: green"
                v-upm__units.digitPrecision="
                  getFeedAndUnits(!row[key] ? 0 : row[key][getRowNameFirst])
                "
              ></p>
              <hr />
              <p
                style="color: blue"
                v-upm__units.digitPrecision="
                  getFeedAndUnits(!row[key] ? 0 : row[key][getRowNameSecond])
                "
              ></p>
            </div>
          </template>
        </slot>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import filtersMixin from "@/mixins/filtersMixin";
import { mapGetters } from "vuex";

export default {
  name: "GraphDataTable",
  mixins: [filtersMixin],
  props: {
    data: {
      default: () => []
    },
    columns: {
      default: () => ({})
    },
    height: {
      default: 300
    },
    rowClass: {
      default: "header-row"
    },
    cellClassName: {
      default: () => {}
    }
  },
  computed: {
    ...mapGetters("user", {
      getUserCountryCode: "getUserCountryCode"
    }),
    getRowNameFirst() {
      const row = this.data[Object.keys(this.data)[0]];
      return Object.keys(row[Object.keys(row)[0]])[0];
    },
    getRowNameSecond() {
      const row = this.data[Object.keys(this.data)[0]];
      return Object.keys(row[Object.keys(row)[0]])[1];
    },

    getFeedAndUnits() {
      return (feed_dispensed) => {
        const totalFeedandUnits = {
          value: feed_dispensed || 0,
          previousunits: this.upm__backendUnits.feed_units,
          units: this.upm__feedUnits,
          modifiersParams: {
            digitPrecision: [0],
            formatNumber: [this.getUserCountryCode]
          },
          displayFormatter: () => {
            return ``;
          }
        };
        return totalFeedandUnits;
      };
    }
  }
};
</script>

<style lang="scss">
@mixin cell-font-size {
  @include responsiveProperty(
    font-size,
    $app_font_size_small,
    $app_font_size_1,
    $app_font_size_2
  );
}
.base-table {
  .header-row {
    color: #233a73;
    border-bottom: 1px solid #bfc4ca;
  }

  .heading {
    display: inline-flex;
    flex-direction: column;

    // position: fixed;
    // top: 120px;
    // background-color: white;
    // z-index: 1;
    // left: 22px;
    // text-align: center;
    // width: 90px;

    // p:nth-of-type(2) {
    //   font-style: italic;
    //   font-weight: 500;
    // }
  }
  .cell {
    // text-align: center;
    @include cell-font-size;
  }
  th {
    // padding: 6px;
  }
  .row-cell {
    // background: aliceblue;
    // width: 60px;
    hr {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
    // p:nth-of-type(1) {
    //   color: green;
    // }
    // p:nth-of-type(2) {
    //   color: blue;
    // }
  }
  // .el-table td:first-child {
  //   font-weight: 700;
  //   color: #233a73 !important;
  // }
}
</style>
