/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: chartOptions.js
Description: This file contains the highcharts configurations for the charts in the pond info tab at customer end for pondlogs.
*/
import { baseChartConfig } from "@/config/baseChartConfig";
import Highcharts from "highcharts";
import { cloneDeep } from "lodash";
// import { CHART_DATE_FORMATS } from "../../../../utils/commonUtils";
import store from "@/store/index";

const dateFormatObject = {
  "dd/MM/yyyy": "%d/%m/%Y",
  "dd MMM": "%d %b",
  "dd MMM, yy": "%d %b, %y"
};

// const preferedDataFormat = dateFormatObject[store.getters["user/getPreferredUnits"].date_format];

const valueFormatter = value => {
  return +value.toFixed(2).toString();
};
const dispensedFeedSeries = function(data, seriesName, color) {
  return {
    visible: true,
    name: seriesName,
    data: data,
    linkedTo: ":previous",
    dashStyle: "Solid",
    color: color,
    zIndex: 0,
    marker: {
      enabled: false
    }
  };
};
const feedLimitSeries = function(data, seriesName, color) {
  return {
    name: seriesName,
    data: data,
    zIndex: 1,
    dashStyle: "dash",
    color: color,
    visible: true
  };
};

const chartMeasurements = {
  spacingBottom: 10,
  marginBottom: 30,
  spacingTop: 15,
  spacingLeft: 1,
  spacingRight: 1,
  height: "250px"
};

const feedChartOptions = cloneDeep(baseChartConfig);
feedChartOptions.tooltip = {
  useHTML: true,
  formatter: function() {
    const time = new Highcharts.Time();
    const groupBySeriesName = this.points.reduce((acc, point) => {
      if (!acc[point.series.name]) {
        acc[point.series.name] = {};
      }
      acc[point.series.name][point.series.userOptions.id] = valueFormatter(
        point.y
      );
      acc[point.series.name].color = point.series.color;
      return acc;
    }, {});
    const pondCount = this.points[0].series.userOptions.pondCount;
    const pointFormatter = (seriesName, objPoints) => {
      let string = "";
      if (!isNaN(+objPoints[seriesName + "_DF"])) {
        string += objPoints[seriesName + "_DF"];
      }
      if (!isNaN(+objPoints[seriesName + "_TF"])) {
        string += (string !== "" ? "/" : "") + objPoints[seriesName + "_TF"];
      }
      if (string === "") return "0/0";
      return string;
    };
    return `<small><strong>${
      pondCount > 1
        ? `DOC: ${this.x}`
        : time.dateFormat(
            dateFormatObject[
              store.getters["user/getPreferredUnits"].date_format
            ],
            this.x
          )
    }</strong></small>
            <table>
            ${Object.keys(groupBySeriesName).reduce((acc, seriesName) => {
              return (
                acc +
                `<tr>
              <td style="color: ${groupBySeriesName[seriesName].color}">
              ${seriesName}: 
              </td>
              <td style="text-align: right">
                <b>${pointFormatter(
                  seriesName,
                  groupBySeriesName[seriesName]
                )}</b>
              </td>
            </tr>`
              );
            }, "")}
            </table>`;
  }
};
const SHOW_Y_AXIS_ON_EMPTY = false;
feedChartOptions.chart = {
  ...chartMeasurements,
  type: "spline",
  resetZoomButton: {
    theme: {
      zIndex: 2000
    }
  },
  zoomType: "x"
};

feedChartOptions.yAxis = {
  title: {
    text: "Feed (kg)"
  },
  labels: {
    x: -5,
    y: 3
  },
  tickAmount: 5,
  showLastLabel: true,
  opposite: false,
  showEmpty: SHOW_Y_AXIS_ON_EMPTY
};

export const feedConfig = {
  chart_options: feedChartOptions,
  series_generators: {
    feed_limit: feedLimitSeries,
    dispensed_feed: dispensedFeedSeries
  },
  yAxis: {
    title: {
      text: "Feed (Kg)"
    },
    opposite: false,
    showEmpty: SHOW_Y_AXIS_ON_EMPTY
  },
  series: [feedLimitSeries, dispensedFeedSeries]
};

const actualABWSeries = {
  name: "feed",
  type: "column",
  zIndex: 1,
  // color: color,
  dashStyle: "dash",
  visible: true,
  stack: 0
};

const actualABWBioSeries = {
  name: "abw",
  type: "spline",
  zIndex: 1,
  // color: color,
  dashStyle: "dash",
  visible: true,
  stack: 0
};

const abwChartOptions = cloneDeep(baseChartConfig);

abwChartOptions.yAxis = [
  {
    gridLineWidth: 0,
    tickAmount: 5,
    title: {
      text: "ABW (g)",
      style: {
        color: "#000000"
      }
    },
    labels: {
      style: {
        color: Highcharts.getOptions().colors[1]
      },
      x: -5,
      y: 3
    },
    showLastLabel: true,
    opposite: false,
    showEmpty: SHOW_Y_AXIS_ON_EMPTY
  },
  {
    gridLineWidth: 1,
    tickAmount: 5,
    title: {
      text: "Biomass (kg)",
      style: {
        color: Highcharts.getOptions().colors[1]
      }
    },
    labels: {
      style: {
        color: "#000000"
      },
      x: 5,
      y: 3
    },
    showLastLabel: true,
    opposite: true,
    showEmpty: SHOW_Y_AXIS_ON_EMPTY
  }
];

abwChartOptions.chart = {
  ...chartMeasurements,
  resetZoomButton: {
    theme: {
      zIndex: 2000
    }
  },
  zoomType: "x"
};
export const ABWConfig = {
  chart_options: abwChartOptions,
  series_generators: {
    actual_abw: actualABWSeries,
    biomass: actualABWBioSeries
  }
};

const doChartOptions = cloneDeep(baseChartConfig);

doChartOptions.tooltip = {
  useHTML: true,
  formatter: function() {
    const time = new Highcharts.Time();
    const groupBySeriesName = this.points.reduce((acc, point) => {
      if (!acc[point.series.name]) {
        acc[point.series.name] = {};
      }
      acc[point.series.name][point.series.userOptions.id] = valueFormatter(
        point.y
      );
      acc[point.series.name].color = point.series.color;
      return acc;
    }, {});
    const pointFormatter = (seriesName, objPoints) => {
      let string = "";
      if (objPoints[seriesName + "_DO"]) {
        string += objPoints[seriesName + "_DO"];
      }
      if (objPoints[seriesName + "_HF"]) {
        string += (string !== "" ? "/" : "") + objPoints[seriesName + "_HF"];
      }
      return string;
    };
    return `<small><strong>${time.dateFormat(
      dateFormatObject[store.getters["user/getPreferredUnits"].date_format],
      this.x
    )}</strong></small>
            <table>
            ${Object.keys(groupBySeriesName).reduce((acc, seriesName) => {
              return (
                acc +
                `<tr>
              <td style="color: ${groupBySeriesName[seriesName].color}">
              ${seriesName}: 
              </td>
              <td style="text-align: right">
                <b>${pointFormatter(
                  seriesName,
                  groupBySeriesName[seriesName]
                )}</b>
              </td>
            </tr>`
              );
            }, "")}
            </table>`;
  }
};

doChartOptions.yAxis = [
  {
    gridLineWidth: 1,
    tickAmount: 5,
    title: {
      text: "Dispensed Feed (kg)",
      style: {
        color: Highcharts.getOptions().colors[1]
      }
    },
    labels: {
      style: {
        color: "#000000"
      },
      x: 3,
      y: 3
    },
    offset: 20,
    opposite: true,
    showEmpty: SHOW_Y_AXIS_ON_EMPTY
  },
  {
    gridLineWidth: 1,
    tickAmount: 5,
    title: {
      text: "DO (mg/L)",
      style: {
        color: Highcharts.getOptions().colors[1]
      }
    },
    labels: {
      style: {
        color: "#000000"
      },
      x: -5,
      y: 3
    },
    opposite: false,
    showEmpty: SHOW_Y_AXIS_ON_EMPTY
  }
];

doChartOptions.chart = {
  ...chartMeasurements,
  resetZoomButton: {
    theme: {
      zIndex: 2000
    }
  },
  zoomType: "x",
  yAxis: {
    showEmpty: SHOW_Y_AXIS_ON_EMPTY
  }
};
export const doConfig = {
  chart_options: doChartOptions
};

export default {
  feedConfig,
  ABWConfig,
  doConfig
};
