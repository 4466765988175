var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('er-data-tables',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading || _vm.tableLoading),expression:"loading || tableLoading"}],key:_vm.$i18n.locale,ref:"pondInfotable",staticClass:"card-all-ponds-table",attrs:{"tableData":_vm.tablePonds,"columns":_vm.columns,"elTableProps":_vm.tableProps,"action-column":false,"sortMethodForColumns":_vm.sortMethods(),"selectedColumnHeaders":_vm.selectedHeaders,"selectedColumnHeadersSortable":true,"uniqueId":"pond-info-table","type":"white-header-table","layout":"table","element-loading-background":"white","searchOnTableData":_vm.pondSearch},on:{"row-click":_vm.handleRowClick},scopedSlots:_vm._u([{key:"title_header",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(header.label))])]}},{key:"title",fn:function(ref){
var row = ref.row;
return [_c('er-text-tag',{attrs:{"text-to-scroll":("" + (_vm.upm__trimSpacesInStr(row.data.title))),"threshold-characters":10}},[_c('span',[_vm._v(_vm._s(_vm.upm__trimSpacesInStr(row.data.title)))])])]}},{key:"days_of_cultivation_header",fn:function(ref){
var header = ref.header;
return [_c('er-text-tag',{attrs:{"text-to-scroll":((header.label) + " " + (header.sub_label)),"threshold-characters":16}},[_c('span',[_vm._v(_vm._s(header.label))]),_vm._v(" "),(header.sub_label.length !== 0)?_c('span',{staticClass:"header-sub-label"},[_vm._v("("+_vm._s(header.sub_label)+")")]):_vm._e()])]}},{key:"days_of_cultivation",fn:function(ref){
var row = ref.row;
return [_vm._v("\n    "+_vm._s(row.data.days_of_cultivation)+"\n  ")]}},{key:"abw_header",fn:function(ref){
var header = ref.header;
return [_c('er-text-tag',{attrs:{"text-to-scroll":((header.label) + " " + (header.sub_label)),"threshold-characters":16}},[_c('span',[_vm._v(_vm._s(header.label))]),_vm._v(" "),_c('span',{staticClass:"header-sub-label"},[_vm._v("("+_vm._s(header.sub_label)+")")]),_vm._v(" "),_c('span',[_c('i',{staticClass:"el-icon-plus",on:{"click":_vm.abwnested}})])])]}},{key:"abw_NestedColumn",fn:function(){return [_vm._l((_vm.abwShowintialCol),function(index){return [_c('el-table-column',{key:index,attrs:{"min-width":55 + 12 * _vm.abwShowColOffset,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-col',{class:("abw-" + (index + _vm.abwShowColOffset)),style:("text-align:left"),attrs:{"tag":"p","title":_vm.ftm__digitPrecision(
                _vm.getPondIdToLatestNABWValues[row._id][
                  index + _vm.abwShowColOffset - 1
                ].abw,
                2 || 0
              ) === 0
                ? ''
                : _vm.getFormattedDate(
                    _vm.getPondIdToLatestNABWValues[row._id][
                      index + _vm.abwShowColOffset - 1
                    ].date
                  )}},[_vm._v("\n            "+_vm._s(_vm.ftm__digitPrecision(
                _vm.getPondIdToLatestNABWValues[row._id][
                  index + _vm.abwShowColOffset - 1
                ].abw,
                1
              ) >= 0.1
                ? _vm.ftm__digitPrecision(
                    _vm.getPondIdToLatestNABWValues[row._id][
                      index + _vm.abwShowColOffset - 1
                    ].abw,
                    2
                  )
                : "--")+"\n          ")])]}}],null,true)})]})]},proxy:true},{key:"awg_header",fn:function(ref){
                var header = ref.header;
return [_c('er-text-tag',{attrs:{"text-to-scroll":((header.label) + " " + (header.sub_label)),"threshold-characters":16}},[_c('span',[_vm._v(_vm._s(header.label))]),_vm._v(" "),_c('span',{staticClass:"header-sub-label"},[_vm._v("("+_vm._s(header.sub_label)+")")]),_vm._v(" "),_c('span',[_c('i',{staticClass:"el-icon-plus",on:{"click":_vm.awgnested}})])])]}},{key:"awg_NestedColumn",fn:function(){return [_vm._l((_vm.awgShowintialCol),function(index){return [_c('el-table-column',{key:index,attrs:{"min-width":55 + 12 * _vm.awgShowColOffset,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('el-col',{class:("abw-" + (index + _vm.awgShowColOffset)),style:("text-align:left"),attrs:{"tag":"p","title":_vm.ftm__digitPrecision(
                _vm.getPondIdToLatestNABWValues[row._id][
                  index + _vm.awgShowColOffset - 1
                ].awg,
                2 || 0
              ) === 0
                ? ''
                : _vm.getFormattedDate(
                    _vm.getPondIdToLatestNABWValues[row._id][
                      index + _vm.awgShowColOffset - 1
                    ].date
                  )}},[_vm._v("\n            "+_vm._s(_vm.ftm__digitPrecision(
                _vm.getPondIdToLatestNABWValues[row._id][
                  index + _vm.awgShowColOffset - 1
                ].awg,
                1
              ) >= 0.1
                ? _vm.ftm__digitPrecision(
                    _vm.getPondIdToLatestNABWValues[row._id][
                      index + _vm.awgShowColOffset - 1
                    ].awg,
                    1
                  )
                : "--")+"\n          ")])]}}],null,true)})]})]},proxy:true},{key:"biomass_header",fn:function(ref){
                var header = ref.header;
return [_c('er-text-tag',{attrs:{"text-to-scroll":((header.label) + " " + (header.sub_label)),"threshold-characters":16}},[_c('span',[_vm._v(_vm._s(header.label))]),_vm._v(" "),_c('span',{staticClass:"header-sub-label"},[_vm._v("("+_vm._s(header.sub_label)+")")])])]}},{key:"biomass",fn:function(ref){
                var row = ref.row;
return [_c('el-row',{staticClass:"cell-container"},[_c('p',{directives:[{name:"upm__units",rawName:"v-upm__units.digitPrecision",value:(_vm.getBiomassAndUnits(row.data.biomass)),expression:"getBiomassAndUnits(row.data.biomass)",modifiers:{"digitPrecision":true}}]})])]}},{key:"do_header",fn:function(ref){
                var header = ref.header;
return [_c('er-text-tag',{attrs:{"text-to-scroll":((header.label) + " " + (header.sub_label)),"threshold-characters":16}},[_c('span',[_vm._v(_vm._s(header.label))]),_vm._v(" "),_c('span',{staticClass:"header-sub-label"},[_vm._v("("+_vm._s(header.sub_label)+")")])])]}},{key:"do",fn:function(ref){
                var row = ref.row;
return [(_vm.getPondIdToLatestNDOTempValues[row.data._id][0].do)?_c('el-popover',{attrs:{"placement":"top-start","width":"200","trigger":"hover","popper-class":"do-pop-container"},on:{"after-enter":function($event){return _vm.onHover(_vm.getPondIdToLatestNDOTempValues[row.data._id])}}},[_c('div',[_c('el-row',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.lastDoDateLoading),expression:"lastDoDateLoading"}],staticClass:"do-last-date",attrs:{"element-loading-spinner":"el-icon-loading"}},[_vm._v("\n          "+_vm._s(_vm.$t("Comn_time"))+":\n          "),_c('span',[_vm._v(_vm._s(_vm.ftm__formatEmpty(_vm.getDoDateSince)))])]),_vm._v(" "),_c('p',{staticClass:"do-hours-label"},[_vm._v(_vm._s(_vm.$t("Comn_last"))+" 3 "+_vm._s(_vm.$t("Hrs")))]),_vm._v(" "),_c('div',[(_vm.getDoInDateRange.length > 0)?_c('img',{staticStyle:{"float":"right","margin-bottom":"2px"},attrs:{"src":require("@/assets/pond-info/down-arrow.svg"),"atl":"down arrow"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"row__bar-container"},[_c('el-row',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.doDateRangeLoading),expression:"doDateRangeLoading"}],staticClass:"bar",attrs:{"element-loading-spinner":"el-icon-loading"}},[(_vm.getDoInDateRange.length > 0)?_vm._l((_vm.getDoInDateRange),function(item,index){return _c('div',{key:("" + (item.color) + index),staticClass:"bar__filler",style:(_vm.getBarStyling(index, _vm.getDoInDateRange.length, item.color))})}):_c('el-tag',[_vm._v(_vm._s(_vm.$t("Comn_no_data")))])],2)],1)])],1),_vm._v(" "),_c('div',{staticStyle:{"display":"flex"},attrs:{"slot":"reference"},slot:"reference"},[_vm._l((_vm.getPondIdToLatestNDOTempValues[
            row.data._id
          ]),function(device,index){return [_c('el-col',{key:index,style:(_vm.generateColorForDo(device)),attrs:{"tag":"p"}},[_vm._v(_vm._s(_vm.ftm__formatEmpty(_vm.ftm__digitPrecision(device.do, 1))))])]})],2)]):_c('div',{staticStyle:{"display":"flex"}},[_vm._l((_vm.getPondIdToLatestNDOTempValues[
          row.data._id
        ]),function(device,index){return [_c('el-col',{key:index,style:(_vm.generateColorForDo(device)),attrs:{"tag":"p"}},[_vm._v(_vm._s(_vm.ftm__formatEmpty(_vm.ftm__digitPrecision(device.do, 1))))])]})],2)]}},{key:"temp_header",fn:function(ref){
        var header = ref.header;
return [_c('er-text-tag',{attrs:{"text-to-scroll":((header.label) + " " + (header.sub_label)),"threshold-characters":16}},[_c('span',[_vm._v(_vm._s(header.label))]),_vm._v(" "),_c('span',{staticClass:"header-sub-label"},[_vm._v("("+_vm._s(header.sub_label)+")")])])]}},{key:"temp",fn:function(ref){
        var row = ref.row;
return [(_vm.getPondIdToLatestNDOTempValues[row.data._id][0].temp)?_c('el-popover',{attrs:{"placement":"top-start","width":"200","trigger":"hover","popper-class":"do-pop-container"},on:{"after-enter":function($event){return _vm.onTempHover(_vm.getPondIdToLatestNDOTempValues[row.data._id])}}},[_c('div',[_c('el-row',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.lastDoDateLoading),expression:"lastDoDateLoading"}],staticClass:"do-last-date",attrs:{"element-loading-spinner":"el-icon-loading"}},[_vm._v("\n          "+_vm._s(_vm.$t("Comn_time"))+":\n          "),_c('span',[_vm._v(_vm._s(_vm.ftm__formatEmpty(_vm.getDoDateSince)))])]),_vm._v(" "),_c('p',{staticClass:"do-hours-label"},[_vm._v(_vm._s(_vm.$t("Comn_last"))+" 3 "+_vm._s(_vm.$t("Hrs")))]),_vm._v(" "),_c('div',[(_vm.getDoInDateRange.length > 0)?_c('img',{staticStyle:{"float":"right","margin-bottom":"2px"},attrs:{"src":require("@/assets/pond-info/down-arrow.svg"),"atl":"down arrow"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"row__bar-container"},[_c('el-row',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.doDateRangeLoading),expression:"doDateRangeLoading"}],staticClass:"bar",attrs:{"element-loading-spinner":"el-icon-loading"}},[(_vm.getDoInDateRange.length > 0)?_vm._l((_vm.getDoInDateRange),function(item,index){return _c('div',{key:("" + (item.color) + index),staticClass:"bar__filler",style:(_vm.getBarStyling(index, _vm.getDoInDateRange.length, item.color))})}):_c('el-tag',[_vm._v(_vm._s(_vm.$t("Comn_no_data")))])],2)],1)])],1),_vm._v(" "),_c('div',{staticStyle:{"display":"flex"},attrs:{"slot":"reference"},slot:"reference"},[_vm._l((_vm.getPondIdToLatestNDOTempValues[
            row.data._id
          ]),function(device,index){return [_c('el-col',{key:index,style:(_vm.generateColorForTemp(device)),attrs:{"tag":"p"}},[_vm._v("\n            "+_vm._s(_vm.ftm__formatEmpty(_vm.ftm__digitPrecision(device.temp, 1)))+"\n          ")])]})],2)]):_c('div',{staticStyle:{"display":"flex"}},[_vm._l((_vm.getPondIdToLatestNDOTempValues[
          row.data._id
        ]),function(device,index){return [_c('el-col',{key:index,style:(_vm.generateColorForDo(device)),attrs:{"tag":"p"}},[_vm._v(_vm._s(_vm.ftm__formatEmpty(_vm.ftm__digitPrecision(device.do, 1))))])]})],2)]}},{key:"feed_header",fn:function(ref){
        var header = ref.header;
return [_c('er-text-tag',{attrs:{"text-to-scroll":((header.label) + " " + (header.sub_label)),"threshold-characters":16,"loading":_vm.loading}},[_c('span',[_vm._v(_vm._s(header.label))]),_vm._v(" "),_c('span',{staticClass:"header-sub-label"},[_vm._v("("+_vm._s(header.sub_label)+")")])])]}},{key:"feed",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.ftm__digitPrecision(row.data.feed, 1) >= 0 ? _vm.accountFormattedFloatValue(_vm.ftm__digitPrecision(row.data.feed, 1), _vm.userCountryCode) : '--'))])]}},{key:"dispensed_feed_header",fn:function(ref){
        var header = ref.header;
return [_c('er-text-tag',{attrs:{"text-to-scroll":((header.label) + " " + (header.sub_label)),"threshold-characters":16,"loading":_vm.loading}},[_c('span',[_vm._v(_vm._s(header.label))]),_vm._v(" "),_c('span',{staticClass:"header-sub-label"},[_vm._v("("+_vm._s(header.sub_label)+")")])])]}},{key:"dispensed_feed",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.ftm__digitPrecision(row.data.dispensed_feed, 1) >= 0 ? _vm.accountFormattedFloatValue(_vm.ftm__digitPrecision(row.data.dispensed_feed, 1), _vm.userCountryCode) : '--'))])]}},{key:"total_feed_header",fn:function(ref){
        var header = ref.header;
return [_c('er-text-tag',{attrs:{"text-to-scroll":((header.label) + " " + (header.sub_label)),"threshold-characters":16,"loading":_vm.loading}},[_c('span',[_vm._v(_vm._s(header.label))]),_vm._v(" "),_c('span',{staticClass:"header-sub-label"},[_vm._v("("+_vm._s(header.sub_label)+")")])])]}},{key:"total_feed",fn:function(ref){
        var row = ref.row;
return [_c('el-popover',{attrs:{"placement":"top","trigger":"hover","popper-class":"total-feed-pop-container"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('p',{staticStyle:{"min-width":"90px"}},[_vm._v(_vm._s(_vm.$t("Comn_manual")))]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.ftm__digitPrecision(row.data.total_dispensed_feed_for_manual, 1) >= 0 ? _vm.accountFormattedFloatValue(_vm.ftm__digitPrecision(row.data.total_dispensed_feed_for_manual, 1), _vm.userCountryCode) : 0)+"\n            ("+_vm._s(_vm.ftm__digitPrecision(row.data.dispensed_feed_for_manual, 1) >= 0 ? _vm.accountFormattedFloatValue(_vm.ftm__digitPrecision(row.data.dispensed_feed_for_manual, 1), _vm.userCountryCode) : 0)+")\n            ")])]),_vm._v(" "),_c('div',{staticStyle:{"display":"flex"}},[_c('p',{staticStyle:{"min-width":"90px"}},[_vm._v(_vm._s(_vm.$t("Comn_pond_mother")))]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.ftm__digitPrecision(row.data.total_dispensed_feed_for_pond_mother, 1) >= 0 ? _vm.accountFormattedFloatValue(_vm.ftm__digitPrecision(row.data.total_dispensed_feed_for_pond_mother, 1), _vm.userCountryCode) : 0)+"\n            ("+_vm._s(_vm.ftm__digitPrecision(row.data.dispensed_feed_for_pond_mother, 1) >= 0 ? _vm.accountFormattedFloatValue(_vm.ftm__digitPrecision(row.data.dispensed_feed_for_pond_mother, 1), _vm.userCountryCode) : 0)+")\n            ")])])]),_vm._v(" "),_c('div',{staticStyle:{"display":"flex"},attrs:{"slot":"reference"},slot:"reference"},[_c('el-row',{attrs:{"type":"flex"}},[_vm._v("\n            "+_vm._s(_vm.ftm__digitPrecision(row.data.total_dispensed_feed, 1) >= 0 ? _vm.accountFormattedFloatValue(_vm.ftm__digitPrecision(row.data.total_dispensed_feed, 1), _vm.userCountryCode) : '--')+"\n            ")])],1)])]}},{key:"feed_per_area_header",fn:function(ref){
        var header = ref.header;
return [_c('er-text-tag',{attrs:{"text-to-scroll":((header.label) + " " + (header.sub_label)),"threshold-characters":16,"loading":_vm.loading}},[_c('span',[_vm._v(_vm._s(header.label))])])]}},{key:"feed_per_area",fn:function(ref){
        var row = ref.row;
return [_c('p',{directives:[{name:"upm__units",rawName:"v-upm__units.digitPrecision",value:(
        _vm.getFeedPerAreaConfig(
          [row.data.total_dispensed_feed, row.data.size],
          ['kg', row.data.area_units]
        )
      ),expression:"\n        getFeedPerAreaConfig(\n          [row.data.total_dispensed_feed, row.data.size],\n          ['kg', row.data.area_units]\n        )\n      ",modifiers:{"digitPrecision":true}}]})]}},{key:"mode_header",fn:function(ref){
      var header = ref.header;
return [_c('span',[_vm._v(_vm._s(header.label))])]}},{key:"mode",fn:function(ref){
      var row = ref.row;
return [_c('span',{style:(_vm.generateColorForMode(row.data))},[_vm._v("\n      "+_vm._s(_vm.mode(row.data))+"\n    ")])]}},{key:"survival_percentage_header",fn:function(ref){
      var header = ref.header;
return [_c('span',[_vm._v(_vm._s(header.label))])]}},{key:"survival_percentage",fn:function(ref){
      var row = ref.row;
return [_c('div',[_c('span',{staticClass:"cell-container column"},[_c('p',{style:(("color:" + (_vm.getProgressBarColor(
            row.data.survival_percentage
          ))))},[_vm._v("\n          "+_vm._s(_vm.ftm__digitPrecision(row.data.survival_percentage, 1))+"%\n        ")])])])]}},{key:"post_larva_stocked",fn:function(ref){
          var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.ftm__digitPrecision(
        _vm.ftm__formatEmpty(
          _vm.ftm__formatNumber(row.data.post_larva_stocked, _vm.userCountryCode)
        ),
        0
      )))])]}},{key:"fcr",fn:function(ref){
      var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.accountFormattedFloatValue(_vm.ftm__digitPrecision(row.data.fcr, 2), _vm.userCountryCode)))])]}},{key:"size_header",fn:function(ref){
      var header = ref.header;
return [_c('er-text-tag',{attrs:{"text-to-scroll":((header.label) + " " + (header.sub_label)),"threshold-characters":16}},[_c('span',[_vm._v(_vm._s(header.label))]),_vm._v(" "),_c('span',{staticClass:"header-sub-label"},[_vm._v("("+_vm._s(header.sub_label)+")")])])]}},{key:"size",fn:function(ref){
      var row = ref.row;
return [_c('p',{directives:[{name:"upm__units",rawName:"v-upm__units.digitPrecision",value:(
        _vm.getPondSize(row.data.size, row.data.area_units)
      ),expression:"\n        getPondSize(row.data.size, row.data.area_units)\n      ",modifiers:{"digitPrecision":true}}]})]}},{key:"pond_mothers_count",fn:function(ref){
      var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.ftm__digitPrecision(row.data.pond_mothers_count, 1)))])]}},{key:"shrimp_talk_working_hours",fn:function(ref){
      var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.ftm__digitPrecision(row.data.shrimp_talk_working_hours, 1) >=0 ? _vm.ftm__digitPrecision(row.data.shrimp_talk_working_hours, 1) : '--'))])]}},{key:"feeder_type",fn:function(ref){
      var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.data.feeder_type ? row.data.feeder_type : '--'))])]}},{key:"feeding_level",fn:function(ref){
      var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.data.feeding_level ? _vm.FeedingLevelTranslations(row.data.feeding_level) : '--'))])]}},{key:"pond_response_quality",fn:function(ref){
      var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.ftm__digitPrecision(row.data.pond_response_quality, 1) >=0 ? _vm.ftm__digitPrecision(row.data.pond_response_quality, 1) : '--'))])]}},{key:"biomass_per_pond_mother_header",fn:function(ref){
      var header = ref.header;
return [_c('er-text-tag',{attrs:{"text-to-scroll":((header.label) + " " + (header.sub_label)),"threshold-characters":16}},[_c('span',[_vm._v(_vm._s(header.label))]),_vm._v(" "),_c('span',{staticClass:"header-sub-label"},[_vm._v("("+_vm._s(header.sub_label)+")")])])]}},{key:"biomass_per_pond_mother",fn:function(ref){
      var row = ref.row;
return [_c('p',{directives:[{name:"upm__units",rawName:"v-upm__units.digitPrecision",value:(_vm.getBiomassAndUnits(row.data.biomass_per_pond_mother)),expression:"getBiomassAndUnits(row.data.biomass_per_pond_mother)",modifiers:{"digitPrecision":true}}]})]}},{key:"biomass_per_ha_header",fn:function(ref){
      var header = ref.header;
return [_c('er-text-tag',{attrs:{"text-to-scroll":((header.label) + " " + (header.sub_label)),"threshold-characters":16}},[_c('span',[_vm._v(_vm._s(header.label))]),_vm._v(" "),_c('span',{staticClass:"header-sub-label"},[_vm._v("("+_vm._s(header.sub_label)+")")])])]}},{key:"biomass_per_ha",fn:function(ref){
      var row = ref.row;
return [_c('p',{directives:[{name:"upm__units",rawName:"v-upm__units.digitPrecision",value:(_vm.getBiomassAndUnits(row.data.biomass_per_ha)),expression:"getBiomassAndUnits(row.data.biomass_per_ha)",modifiers:{"digitPrecision":true}}]})]}},{key:"shrimps_per_pond_mother",fn:function(ref){
      var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.ftm__digitPrecision(row.data.shrimps_per_pond_mother, 1) >=0 ? _vm.accountFormattedFloatValue(_vm.ftm__digitPrecision(row.data.shrimps_per_pond_mother, 1), _vm.userCountryCode) : '--'))])]}},{key:"stocking_density",fn:function(ref){
      var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.ftm__digitPrecision(row.data.stocking_density, 1) >=0 ? _vm.accountFormattedFloatValue(_vm.ftm__digitPrecision(row.data.stocking_density, 1), _vm.userCountryCode) : '--'))])]}},{key:"total_dispensed_feed_for_manual_header",fn:function(ref){
      var header = ref.header;
return [_c('er-text-tag',{attrs:{"text-to-scroll":((header.label) + " " + (header.sub_label)),"threshold-characters":16}},[_c('span',[_vm._v(_vm._s(header.label))]),_vm._v(" "),_c('span',{staticClass:"header-sub-label"},[_vm._v("("+_vm._s(header.sub_label)+")")])])]}},{key:"total_dispensed_feed_for_manual",fn:function(ref){
      var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.ftm__digitPrecision(row.data.total_dispensed_feed_for_manual, 1) >= 0 ?
      _vm.accountFormattedFloatValue(_vm.ftm__digitPrecision(row.data.total_dispensed_feed_for_manual, 1), _vm.userCountryCode) : '--'))])]}},{key:"total_dispensed_feed_in_auto_mode_header",fn:function(ref){
      var header = ref.header;
return [_c('er-text-tag',{attrs:{"text-to-scroll":((header.label) + " " + (header.sub_label)),"threshold-characters":16}},[_c('span',[_vm._v(_vm._s(header.label))]),_vm._v(" "),_c('span',{staticClass:"header-sub-label"},[_vm._v("("+_vm._s(header.sub_label)+")")])])]}},{key:"total_dispensed_feed_in_auto_mode",fn:function(ref){
      var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.ftm__digitPrecision(row.data.total_dispensed_feed_in_auto_mode, 1) >= 0 ?
      _vm.accountFormattedFloatValue(_vm.ftm__digitPrecision(row.data.total_dispensed_feed_in_auto_mode, 1), _vm.userCountryCode) : '--'))])]}},{key:"total_dispensed_feed_in_schedule_mode_header",fn:function(ref){
      var header = ref.header;
return [_c('er-text-tag',{attrs:{"text-to-scroll":((header.label) + " " + (header.sub_label)),"threshold-characters":16}},[_c('span',[_vm._v(_vm._s(header.label))]),_vm._v(" "),_c('span',{staticClass:"header-sub-label"},[_vm._v("("+_vm._s(header.sub_label)+")")])])]}},{key:"total_dispensed_feed_in_schedule_mode",fn:function(ref){
      var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.ftm__digitPrecision(row.data.total_dispensed_feed_in_schedule_mode, 1) >= 0 ?
            _vm.accountFormattedFloatValue(_vm.ftm__digitPrecision(row.data.total_dispensed_feed_in_schedule_mode, 1), _vm.userCountryCode) : '--'))])]}},{key:"total_dispensed_feed_in_basic_mode_header",fn:function(ref){
            var header = ref.header;
return [_c('er-text-tag',{attrs:{"text-to-scroll":((header.label) + " " + (header.sub_label)),"threshold-characters":16}},[_c('span',[_vm._v(_vm._s(header.label))]),_vm._v(" "),_c('span',{staticClass:"header-sub-label"},[_vm._v("("+_vm._s(header.sub_label)+")")])])]}},{key:"total_dispensed_feed_in_basic_mode",fn:function(ref){
            var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.ftm__digitPrecision(row.data.total_dispensed_feed_in_basic_mode, 1) >= 0 ?
          _vm.accountFormattedFloatValue(_vm.ftm__digitPrecision(row.data.total_dispensed_feed_in_basic_mode, 1), _vm.userCountryCode) : '--'))])]}},{key:"shrimp_talks_count",fn:function(ref){
          var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.ftm__digitPrecision(row.data.shrimp_talks_count, 1)))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }